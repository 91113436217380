import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

import stripHTML from "../../utils/stripHTML";
import LogoSchema from "./Schemas/LogoSchema";
import WebpageSchema from "./Schemas/WebpageSchema";
import getOgImageLink from "../../utils/getOgImageLink";

const config = require("../../config");

const SEO = ({ title, description, image }) => {
  const { pathname } = useLocation();
  // eslint-disable-next-line no-use-before-define
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;

  let path = pathname;

  if (path && path.startsWith("/")) {
    path = path.substring(1);
  }

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || defaultImage,
    url: `${siteUrl}/${path}`,
  };

  let seoUrl = seo.url;
  if (seoUrl.slice(-1) === "/") {
    seoUrl = seoUrl.slice(0, -1);
  }

  const keywords = `${seo.title} ${seo.description}`
    .split(" ")
    .filter((keyword) => keyword && keyword !== "")
    .join(", ");

  // const metaImageUrl =
  //   seoUrl !== siteUrl ? getOgImageLink(seo.title) : config.postThumbnail;

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta charSet="UTF-8" />
      <meta name="theme-color" content={config.mainColor} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <meta name="author" property="og:author" content={config.legalTitle} />
      <meta name="description" content={stripHTML(seo.description)} />
      <meta name="image" content={seo.image} />
      <meta name="keywords" content={keywords} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {seo.url && <link rel="canonical" href={seo.url} />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={stripHTML(seo.description)} />
      )}

      {/* <meta property="og:image" content={metaImageUrl} />

      <meta name="twitter:image" content={metaImageUrl} /> */}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={stripHTML(seo.description)} />
      )}

      <link
        rel="shortcut icon"
        href="https://www.silikonvadisi.co/favicon.ico"
        type="image/x-icon"
      />
      <link rel="preconnect" href="https://www.silikonvadisi.co" />
      <link rel="dns-prefetch" href="https://res.cloudinary.com/" />
      <link rel="preconnect" href="https://res.cloudinary.com/" />
      <link rel="preconnect" href="https://fonts.googleapis.com/" />
      <link rel="preconnect" href="https://fonts.gstatic.com/" />
      <link rel="preconnect" href="https://cdn.builder.io/" />
      <link rel="preconnect" href="https://dl.airtable.com/" />

      {[
        "regular.woff2",
        "300.woff2",
        "600.woff2",
        "700.woff2",
        "800.woff2",
        "900.woff2",
        "regular.woff",
        "300.woff",
        "600.woff",
        "700.woff",
        "800.woff",
        "900.woff",
      ].map((fileName) => (
        <link
          key={fileName}
          rel="preload"
          href={`/fonts/nunito-sans-v6-latin/nunito-sans-v6-latin-${fileName}`}
          as="font"
          type={`font/${fileName.split(".")[1]}`}
          crossOrigin="anonymous"
        />
      ))}

      {/* Schemas */}

      {seo.url && (
        <script type="application/ld+json">
          {LogoSchema(seo.url, `${config.siteUrl}/favicon.ico`)}
        </script>
      )}
      {seo.title && seo.description && (
        <script type="application/ld+json">
          {WebpageSchema(seo.title, seo.description, config.title)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        # defaultImage: image
        twitterUsername
      }
    }
  }
`;
