import React from "react";
import { useTranslation } from "react-i18next";


const Confirmed = () => {
  const { t } = useTranslation();

  return (
    <div>
        <div class="section newsletter wf-section">
            <div class="container">
                <div
                    class="newsletter-wrapper"
                >
                    <div class="vertical-container">
                        <div class="form-newsletter">
                            <div
                            role="region"
                            aria-label="Email Form success"
                            >
                            <div>
                                <p>{t("confirmed.heading")} </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
  );
};

export default Confirmed;
