import React from "react";
import { PopupButton } from "@typeform/embed-react";

import "../../styles/webflow.css";
import "./EmailList.css";

const Newsletter = () => {
  return (
    <div class="section wf-section go-to-top" style={{ background: "white" }}>
      <div class="container">
        <div class="newsletter-wrapper">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScIp3YZxOCgd7V2pon9_0Ido9haO0OhYj-AEA-yoeIzCccWUQ/viewform"
            target="_blank"
          >
            <img
              src="https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/d976bbd7-9033-4292-399f-413724254200/public"
              loading="lazy"
              alt=""
            />
          </a>

          {/* Typeform  */}
          {/* <PopupButton id="gun8G8RP">
      <img
              src="https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/d976bbd7-9033-4292-399f-413724254200/public"
              loading="lazy"
              alt=""
            />
      </PopupButton> */}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
