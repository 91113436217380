import React from 'react'
import Hero from '../components/Hero/Hero.jsx'
import Newsletter from '../components/Newsletter/Newsletter.jsx'
import ContentList from "../components/ContentList/"
import EmailList from '../components/EmailList/EmailList.jsx'

export default function index() {
  return (
    <>
      <div style={{marginTop: "50px", marginBottom: "-150px"}}>
          <EmailList />
      </div>
        <div>
            <Hero />
            <EmailList />
            <ContentList />
            <Newsletter />
            <div style={{marginTop: "50px", marginBottom: "50px"}}>
            <EmailList />
        </div>
      </div>
    </>

  )
}
