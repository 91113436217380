// using module.exports here so gatsby-config can read it

const siteUrlDefault = "https://www.silikonvadisi.co";
const config = {
  title: "Silikon Vadisi",
  titleTemplate: "%s",
  siteUrl: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  lealTitle: "CTO Lab Inc.",
  description: "CTO Lab",
  url: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  postThumbnail: "",
  twitterUsername: "",
  airtableTables: ["Blog"],
  mainColor: "#4B8EF9",
  // those fields are required for the page generation process.
  airtableSpecialFields: ["slug", "title", "description"],
};

module.exports = config;
