import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import blog from "../../../data/data-blog.json";

import "./styles.scss";

interface BlogItem {
  published: boolean;
  id: number;
  category: string;
  slug: string;
  image: string;
  date: string;
  title: string;
  excerpt?: string;
}

interface State {
  blogData: BlogItem[];
  categories: string[];
}

export default class ContentList extends React.Component<{}, State> {
  blogData: BlogItem[] = blog as BlogItem[];

  categories: string[] = [
    ...new Set(
      this.blogData.filter((item) => item.category).map((item) => item.category)
    ),
  ];

  state: State = {
    blogData: this.blogData,
    categories: this.categories,
  };

  settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  renderBlogItemsByCategory = (category: string) => {
    const filteredBlogs = this.state.blogData
      .filter(
        (item) =>
          item.category === category &&
          item.image &&
          item.date &&
          item.published === true
      )
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    return filteredBlogs.map((blog: BlogItem | any) => (
      <div key={blog.id} role="listitem" className="w-dyn-item">
        <a href={blog.slug} className="post-grid w-inline-block">
          <div id={`w-node-${blog.id}`} className="image-post small">
            <div className="small-text white">{blog.date}</div>
            <img
              src={blog.image + `mid`}
              loading="lazy"
              alt=""
              className="image absolute"
            />
          </div>
          <h3 className="h3 titlecase">{blog.title}</h3>
          <p className="small-text">
            {blog.excerpt
              ? `${blog.excerpt.split(" ").slice(0, 20).join(" ")}${
                  blog.excerpt.split(" ").length > 20 ? "..." : ""
                }`
              : ""}
          </p>
        </a>
      </div>
    ));
  };

  render() {
    return (
      <div className="wf-section" id="life">
        <div className="container">
          {this.state.categories.map((category: string) => {
            const categoryBlogs = this.renderBlogItemsByCategory(category);
            if (categoryBlogs.length === 0) return null;
            return (
              <div className="w-dyn-list" key={category}>
                <div
                  data-w-id="b7302085-3f84-ce0c-0989-70d61b7dfb5d"
                  role="listitem"
                  className="category-header w-dyn-item"
                >
                  <h2 className="h2">{category}</h2>
                </div>
                <div className="slider">
                  <Slider {...this.settings}>{categoryBlogs}</Slider>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
