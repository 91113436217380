/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React from "react";
import { builder, BuilderComponent } from "@builder.io/react";
import kebabCase from "kebab-case";
import Link from "../components/Link/Link";
import SEO from "../components/SEO/SEO";
import EmailList from "../components/EmailList/EmailList.jsx";

builder.init(process.env.BUILDER_API_KEY);

const Template = ({ pageContext }) => {
  const { tableName, airtableData, builderDesign } = pageContext;
  let newUrl = airtableData.image
    ? airtableData.image.slice(3, -5)
    : "https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/117aad17-92a3-40bf-a53b-a575741c6b00/";
  let newCategory = airtableData.category
    ? airtableData.category.slice(3, -5)
    : "LingoEdit";
  return (
    <>
      {airtableData && (
        <SEO
          title={airtableData.title}
          description={airtableData.description}
        />
      )}
      <div style={{ marginTop: "50px", marginBottom: "-150px" }}>
        <EmailList />
      </div>
      <div class="section margin wf-section">
        <div class="container">
          <div
            data-w-id="bff9d3c6-caa3-3796-e08d-a48fa1ce7e28"
            class="center-container bottom-margin"
          >
            <div class="horizontal-container">
              <div class="bold-text right-margin">-</div>
              <div class="bold-text">{newCategory}</div>
              <div class="bold-text left-margin">-</div>
            </div>
            <h1 class="h1 titlecase">{airtableData.title}</h1>
            <div class="horizontal-container">
              <div class="small-text">By</div>
              <div class="small-text author">
                {airtableData.author === 1 ? "Sahin Boydas" : "SV Hawks"}
              </div>
            </div>
          </div>
          <div class="main-image">
            <img
              data-w-id="59718b17-ca90-2e75-0d9f-d3af496ddee2"
              loading="lazy"
              src={newUrl + "public"}
              alt=""
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 90vw, (max-width: 1919px) 780px, 980px"
              class="image"
            />
          </div>
          <div class="rich-text w-richtext">
            <article className="builder-blocks">
              {builderDesign && airtableData && (
                <BuilderComponent
                  renderLink={Link}
                  name={kebabCase(tableName)}
                  content={builderDesign}
                  data={airtableData}
                />
              )}
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template;
