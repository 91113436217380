import React from "react";
import { addToAirtable } from "../components/Newsletter/airtableEmail.js";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";


const Contact = () => {
  const { t } = useTranslation();


  const handleSubmit = async (event) => {
    event.preventDefault();
    const name = event.target.elements[0].value;
    const email = event.target.elements[1].value;
    const message = event.target.elements[2].value;
  
    await addToAirtable(name, email, message);
    navigate("/confirmed"); // Navigate to the /confirmed page
  };

  return (
    <>
    <div class="section hero wf-section">
      <div class="container">
        <div class="center-container">
          <div class="h2 bold-text">- {t("contact.heading")} -</div>
          <h1 class="h1">{t("contact.getInTouch")}</h1>
        </div>
      </div>
    </div>
    <div class="section contact wf-section">
      <div class="container">
        <div class="form-block">
          <form onSubmit={handleSubmit} class="form">
            <label for="name-2" class="label">
            {t("contact.name")}
            </label>
            <label
              for="Name-3"
              id="w-node-df792d96-e309-f22a-cc90-019f5db8e482-5b0f9bcf"
              class="label"
            >
              {t("contact.email")}
            </label>
            <input
              type="text"
              class="text-field w-node-df792d96-e309-f22a-cc90-019f5db8e484-5b0f9bcf w-input"
              placeholder="Eg. Mark Twain"
              required
            />
            <input
              type="email"
              class="text-field w-node-df792d96-e309-f22a-cc90-019f5db8e485-5b0f9bcf w-input"
              placeholder="Eg. marktwain@gmail.com"
              required
            />
            <label
              for="field-2"
              id="w-node-df792d96-e309-f22a-cc90-019f5db8e489-5b0f9bcf"
              class="label top-margin"
            >
              {t("contact.message")}
            </label>
            <textarea
              id="field-2"
              name="field-2"
              maxlength="5000"
              data-name="Field 2"
              placeholder="Type anything..."
              required=""
              class="textarea w-node-df792d96-e309-f22a-cc90-019f5db8e48b-5b0f9bcf w-input"
            ></textarea>
            <input
              type="submit"
              value={t("contact.submit")}
              class="primary-button width w-button"
              name="submit"
            />
            <div data-lastpass-icon-root="true"></div>

            <label class="w-checkbox checkbox-field" required>
              <input
                type="checkbox"
                id="checkbox"
                name="checkbox"
                data-name="Checkbox"
                required="on"
              />
              <span class="checkbox-label w-form-label" for="checkbox">
                <a
                  href="/gizlilik-politikamız"
                  class="newsletter-link"
                  style={{ marginLeft: "10px" }}
                >
                  {t("contact.privacyPolicy")}
                </a>{" "}
                {t("contact.didYouRead")}
              </span>
            </label>
            <label class="w-checkbox checkbox-field" required>
              <input
                type="checkbox"
                id="checkbox"
                name="checkbox"
                data-name="Checkbox"
                required="on"
              />
              <span class="checkbox-label w-form-label" for="checkbox">
                <a
                  href="/acik-riza"
                  class="newsletter-link"
                  style={{ marginLeft: "10px" }}
                >
                  {t("contact.explicitConsent")}
                </a>{" "}
                {t("contact.didYouRead")}
              </span>
            </label>
            <div data-lastpass-icon-root="true"></div>
          </form>
        </div>
      </div>
    </div>
  </>
  );
};

export default Contact;
