import Airtable from "airtable";

const airtableBase = new Airtable({ apiKey: "keyAasbmgP7gLAk8F" }).base(
  "appyixX7iQDhb7BmS"
);

export const addToAirtable = async (email, name, message) => {
    try {
      const records = await airtableBase("emailList").create([
        {
          fields: {
            email: email,
            name: name,
            message: message,
          },
        },
      ]);
  
      console.log(records[0].getId());
    } catch (error) {
      console.error(error);
    }
  };
  
