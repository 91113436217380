import React from "react";
import { useTranslation } from "react-i18next";

import Micro from "../../layouts/Micro";
import "../../styles/webflow.css";
import logo from "../../styles/svcologosiyah.png";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Micro>
      <footer className="footer micro">
        <div class="section footer wf-section">
          <div class="container">
            <div class="footer-grid">
              <div class="content-footer">
                <a href="/" aria-current="page" class="w-nav-brand w--current">
                  <img
                    src={logo}
                    loading="lazy"
                    alt=""
                    class="logo-icon"
                    style={{ width: "200px" }}
                  />
                </a>
                <div class="small-text footer">
                {t("footer.belowLogo")}
                </div>
              </div>
              <div class="content-footer">
                <div class="small-text title">{t("footer.menu.title")}</div>
                <a href="/" class="link" style={{ color: "white" }}>
                {t("footer.menu.home")}
                </a>
                <a href="/explicit" class="link" style={{ color: "white" }}>
                {t("footer.menu.explicitConsent")}
                </a>
                <a
                  href="/privacy"
                  class="link"
                  style={{ color: "white" }}
                >
                  {t("footer.menu.privacyPolicy")}
                </a>
              </div>
              <div class="content-footer">
                <div class="small-text title">{t("footer.menu.title")}</div>
                <a href="/contact" class="link" style={{ color: "white" }}>
                {t("footer.contact.contact")}
                </a>
                <a href="/contact" class="link" style={{ color: "white" }}>
                {t("footer.contact.joinUs")}
                </a>
                <a href="/contact" class="link" style={{ color: "white" }}>
                {t("footer.contact.tenkTurk")}
                </a>
              </div>
              <div class="content-footer">
                <div class="small-text title">{t("footer.socialMedia.title")}</div>
                <div class="social-links">
                  <a href={t("footer.socialMedia.twitter")} class="social-link w-inline-block" target="_blank">
                    <img
                      src="https://assets.website-files.com/6246f628ff60cf28f937a441/6249e9e207e477e93618d711_Twitter.svg"
                      loading="lazy"
                      alt="twitter"
                    />
                  </a>
                  <a href={t("footer.socialMedia.instagram")} class="social-link margin w-inline-block" target="_blank">
                    <img
                      src="https://assets.website-files.com/6246f628ff60cf28f937a441/6249e9e207e4777d1818d713_Instagram.svg"
                      loading="lazy"
                      alt="instagram"
                    />
                  </a>
                  <a href={t("footer.socialMedia.youtube")} class="social-link w-inline-block" target="_blank">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/174/174883.png?w=1380&t=st=1684934875~exp=1684935475~hmac=0b5ff83d2fdac2cc21c02bea22a7cfc6e00cc9286d8429173e9bad512ff690e5"
                      loading="lazy"
                      alt="youtube"
                      style={{width: "30px"}}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="footer-bottom">
              <div className="text-right" style={{ color: "white" }}>
                Designed in{" "}
                <span role="img" aria-label="Sun">
                  ☀️
                </span>{" "}
                California &amp; Built Globally With{" "}
                <span role="img" aria-label="Heart">
                  ❤️
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Micro>
  );
};

export default Footer;
